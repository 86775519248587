import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatAutocompleteOptionsScrollDirective } from './mat-autocomplete-options-scroll.directive';



@NgModule({
  declarations: [
    MatAutocompleteOptionsScrollDirective
  ],
  imports: [
    CommonModule
  ],
  exports:[
    MatAutocompleteOptionsScrollDirective
  ]
})
export class MatAutocompleteOptionsScrollModule { }
