import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from "@ngx-translate/core";

import { GlobalLoadingComponent } from "./global-loading.component";
import { ProgressSpinnerModule } from "../progress-spinner/progress-spinner.module";

@NgModule({
    declarations: [
        GlobalLoadingComponent
    ],
    imports: [
        CommonModule,
        ProgressSpinnerModule,
        TranslateModule
    ],
    exports: [
        GlobalLoadingComponent
    ]
})
export class GlobalLoadingModule { }
