import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";

import { TranslateModule } from "@ngx-translate/core";

import { IconComponent } from "./icon.component";

@NgModule({
    declarations: [
        IconComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatBadgeModule
    ],
    exports: [
        IconComponent
    ]
})
export class IconModule{}
