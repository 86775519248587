<mat-icon [class]="classes + ' icon'"
          [matBadge]="(badge?.value | async) || null"
          [matBadgeColor]="badge.color"
          [matBadgeHidden]="badge.hidden"
          [color]="color"
          [matBadgeOverlap]="badge.overlap"
          [matBadgePosition]="badge.position"
          [matBadgeSize]="badge.size"
          [matBadgeDescription]="(badge.description) | translate"
          [matBadgeDisabled]="badge.disabled"
          [svgIcon]="svgIcon"
          *ngIf="icon || svgIcon">
    {{ icon }}
</mat-icon>
