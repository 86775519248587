export enum FormMessageTypesEnum {
    REQUIRED,
    EMAIL,
    MINLENGTH,
    MAXLENGTH,
    MAT_DATE_PICKER_PARSE,
    MAT_START_DATE_INVALID,
    MAT_END_DATE_INVALID,
    MAT_DATE_PICKER_FILTER,
    DATE_RANGE_MESSAGE_SEPARATED,
    URL,
    CNPJ,
    CPF_OR_EMAIL,
    MIN,
    MAX,
    CEP,
    PHONE,
    INVALID_COLOR,
    CPF,
    HOUR_MINUTE,
    CNH,
    LICENSE_PLATE,
    CUSTOM_ERROR
}
