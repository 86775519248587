import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from "@ngx-translate/core";

import { CopyrightComponent } from './copyright.component';
import { LinkModule } from "../link/link.module";

@NgModule({
    declarations: [CopyrightComponent],
    exports: [
        CopyrightComponent
    ],
    imports: [
        CommonModule,
        LinkModule,
        TranslateModule
    ]
})
export class CopyrightModule { }
