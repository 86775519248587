import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { TranslateModule } from "@ngx-translate/core";

import { ProgressSpinnerComponent } from "./progress-spinner.component";

@NgModule({
    declarations: [
        ProgressSpinnerComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatProgressSpinnerModule
    ],
    exports: [
        ProgressSpinnerComponent
    ]
})
export class ProgressSpinnerModule{}
