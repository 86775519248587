import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRippleModule } from "@angular/material/core";

import { TranslateModule } from "@ngx-translate/core";

import { AvatarComponent } from './avatar.component';
import { IconModule } from "../icon/icon.module";

@NgModule({
    declarations: [
        AvatarComponent
    ],
    exports: [
        AvatarComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        IconModule,
        MatTooltipModule,
        MatRippleModule
    ]
})
export class AvatarModule { }
